import React, { FC, ImgHTMLAttributes, useEffect, useRef, useState } from 'react'
import { container, imgStyles, pictureStyles } from './styles'
import { theme } from '@config/theme'
import { Theme, ThemeSystemProps } from 'theme-system'
import { useInView } from 'react-intersection-observer'
import { useWindowWidth } from '@lib/hooks'
import { cx } from '@linaria/core'

const imageCache = {}

const inImageCache = (src: string) => imageCache[src] || false

const addToCache = (src: string) => {
  imageCache[src] = true
}

const getSources = (sources: string[]) => {
  const breakpoints = Object.values(theme.breakpoints)
  return sources
    .map((src: string, index: number) => (
      <source
        key={index}
        media={index !== 0 ? `(min-width: ${breakpoints[index - 1]})` : undefined}
        srcSet={src}
      />
    ))
    .reverse()
}

export type ImageProps = Omit<ThemeSystemProps<Theme>, 'height' | 'width'> &
  Omit<ImgHTMLAttributes<HTMLImageElement>, 'loading' | 'height' | 'width'> & {
    ratio?: number[]
    height?: string[]
    width?: string[]
    sources: string[]
    placeholderSrc?: string
    loading?: 'lazy' | 'eager' | 'delayed' | 'none'
    objectFit?: 'cover' | 'contain'
    objectPosition?: 'center' | 'top' | 'bottom'
  }
export const Image: FC<ImageProps> = ({
  ratio,
  sources,
  placeholderSrc,
  loading = 'lazy',
  height,
  width,
  alt,
  className,
  position = 'relative',
  bg = 'primary',
  objectFit = 'cover',
  objectPosition = 'top',
  ...rest
}) => {
  const [hasNativeLazyLoadSupport, setHasNativeLazyLoadSupport] = useState(false)
  // base initial value on presence in cache.
  // if sources[0] is present in cache, never animate image in
  const [isImageLoaded, setIsImageLoaded] = useState(inImageCache(sources[0]))
  const [isMounted, setIsMounted] = useState(false)
  const windowWidth = useWindowWidth()
  const [sizeStyles, setSizeStyles] = useState(null)
  const [sizeType, setSizeType] = useState(height && width ? 'dimensions' : 'ratio')
  const pictureRef = useRef<HTMLImageElement>()
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '500px',
  })
  const isCritical = loading === 'eager'
  const isLazy = loading === 'lazy'
  const isDelayed = loading === 'delayed'

  const isBrowser = typeof window !== 'undefined'
  const hasIOSupport = isBrowser && typeof window.IntersectionObserver !== 'undefined'

  // Determine if the picture tag should be rendered
  // In these cases picture should render:
  // - When critical
  // - Lazy && No IO support
  // - Lazy && In View
  // - isDelayed && image is loaded
  const shouldRenderPicture =
    isCritical ||
    (isLazy && isBrowser && !hasIOSupport) ||
    (inView && isLazy) ||
    (isMounted && isDelayed)

  const isImageVisible = isCritical || !hasIOSupport || isImageLoaded // if loading is eager or no IO, or if image is loaded, set opacity to 1

  const handleOnLoad = () => {
    setIsImageLoaded(true)
    if (!inImageCache(sources[0])) {
      addToCache(sources[0])
    }
  }

  // Don't really like this solution but
  // since we can't pass props to style dynamically in Linaria we watch for (debounced) changes on Window size:
  // -- determine which theme breakpoint is applicable at this resolution
  // -- apply corresponding height & width based on breakpoint and input arrays OR
  // -- apply corresponding ratio based on breakpoint and input array
  // -- set type of size ('dimensions' | 'ratio') and pass as data-prop to container
  // -- set styling object to be used inline in container
  // -- return null if no width, height and ratio is defined
  //
  // Pros
  // -- useWindowWidth is relatively cheap, nobody resizes their window often + it's debounced
  // -- users see a preloading image without any weird layout changes
  // -- responsive
  //
  // Cons
  // -- we use JS for responsive styling instead of CSS, so we have to wait for window before we know which sizing is applicable
  const breakpoints = Object.values(theme.breakpoints)
  const applicableBreakpoint = breakpoints
    .map((value) => windowWidth >= parseInt(value, 10))
    .lastIndexOf(true)

  let elHeight = height ? height[0] : 'auto'
  let elWidth = width ? width[0] : 'auto'
  let elRatio = ratio ? ratio[0] : 0

  if (applicableBreakpoint !== -1 && width && height) {
    elHeight = height[applicableBreakpoint + 1]
      ? height[applicableBreakpoint + 1]
      : height[height.length - 1]
    elWidth = width[applicableBreakpoint + 1]
      ? width[applicableBreakpoint + 1]
      : width[width.length - 1]
  }

  if (applicableBreakpoint !== -1 && ratio) {
    elRatio = ratio[applicableBreakpoint + 1]
      ? ratio[applicableBreakpoint + 1]
      : ratio[ratio.length - 1]
  }

  useEffect(() => {
    setSizeType(height && width ? 'dimensions' : 'ratio')
    setSizeStyles(
      sizeType === 'dimensions'
        ? {
            height: elHeight,
            width: elWidth,
          }
        : {
            '--ratio': `${elRatio * 100}%`,
          }
    )
  }, [windowWidth, height, width, ratio]) // eslint-disable-line

  useEffect(() => {
    setIsMounted(true)

    // prevent hydration issue
    setHasNativeLazyLoadSupport(
      typeof HTMLImageElement !== `undefined` && `loading` in HTMLImageElement.prototype
    )
  }, [])

  if (!width && !height && !ratio) {
    console.error('Please pass height and width OR ratio')
    return null
  }

  if (!ratio && ((!width && height) || (width && !height))) {
    console.warn('If not using ratio for sizing, please pass BOTH height and width')
    return null
  }
  return (
    <div
      className={cx(container, className)}
      style={{ position, ...sizeStyles }}
      data-position-type={position}
      data-size-type={sizeType}
      ref={ref}
      suppressHydrationWarning
      {...rest}
    >
      {!isCritical && (!hasNativeLazyLoadSupport || loading === 'none') ? (
        <img
          className={imgStyles}
          aria-hidden="true"
          src={placeholderSrc}
          alt=""
          style={{
            opacity: shouldRenderPicture ? 0 : 1,
            objectFit,
            objectPosition,
          }}
          {...rest}
        />
      ) : null}

      {shouldRenderPicture && windowWidth > 0 && (
        <picture
          className={pictureStyles}
          style={{ opacity: isImageVisible ? 1 : 0, objectFit, objectPosition }}
        >
          {getSources(sources)}
          <img
            suppressHydrationWarning
            onLoad={handleOnLoad}
            src={sources[0]}
            ref={pictureRef}
            alt={alt}
            style={{ objectFit, objectPosition }}
            // @ts-ignore
            loading={hasNativeLazyLoadSupport && loading !== 'delayed' ? loading : undefined}
          />
        </picture>
      )}
    </div>
  )
}

export default Image
