import React, { PropsWithChildren } from 'react';
import { Menu as ReachMenu, MenuButton } from '@reach/menu-button';
import Box from '@components/ui/layout/Box';
import { Text } from '@components/ui/typography';
type Props = {
  buttonText: string;
};
const buttonClass = "b1rb8qr8";
export const Menu = ({
  buttonText,
  children
}: PropsWithChildren<Props>) =>
// the added relative wrapper is necessary to align the MenuList
<Box position="relative">
    <ReachMenu>
      {({
      isExpanded
    }) => {
      const style = {
        display: 'inline-block',
        transform: `rotate(${isExpanded ? -180 : 0}deg)`
      };
      return <>
            <MenuButton style={{
          opacity: isExpanded ? '0.5' : '1',
          outline: 0
        }}>
              <Text as="span" variant="bodySmall" fontWeight="medium" color="purpleWeb" className={buttonClass}>
                {buttonText}
                <span {...{
              style
            }}>▾</span>
              </Text>
            </MenuButton>
            {children}
          </>;
    }}
    </ReachMenu>
  </Box>;

require("./Menu.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Menu.tsx");