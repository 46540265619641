import React, { PropsWithChildren } from 'react';
import { MenuList as ReachMenuList } from '@reach/menu-button';
const styles = "s1iv4dx4";
type Props = {
  menuOffset?: 'left' | 'right' | 'top';
};
export const MenuList = ({
  menuOffset = 'left',
  children
}: PropsWithChildren<Props>) => {
  return <ReachMenuList className={styles} portal={false} data-offset={menuOffset}>
      {children}
    </ReachMenuList>;
};

require("./MenuList.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./MenuList.tsx");