import React from 'react'
import { Menu, MenuList, MenuItem } from '@components/ui/menu-button'
export function MenuButton({
  fscAction,
  fscItemPathValues,
  link1title,
  link1url,
  link2title,
  link2url,
  offset,
  title,
}: {
  fscAction?: string
  fscItemPathValues?: string[]
  link1title: string
  link1url: string
  link2title?: string
  link2url?: string
  offset: 'left' | 'right' | 'top'
  title: string
}) {
  return (
    <Menu buttonText={title}>
      <MenuList menuOffset={offset}>
        <Link href={link1url} nr={0} {...{ fscAction, fscItemPathValues }}>
          <MenuItem onSelect={() => {}}>{link1title}</MenuItem>
        </Link>
        {link2title && link2url && (
          <Link href={link2url} nr={1} {...{ fscAction, fscItemPathValues }}>
            <MenuItem onSelect={() => {}}>{link2title}</MenuItem>
          </Link>
        )}
      </MenuList>
    </Menu>
  )
}

function Link({
  children,
  href,
  nr,
  fscAction,
  fscItemPathValues,
}: {
  children: any
  href: string
  nr: number
  fscAction?: string
  fscItemPathValues?: string[]
}) {
  return fscAction ? (
    <a data-fsc-action={fscAction} data-fsc-item-path-value={fscItemPathValues[nr]} {...{ href }}>
      {children}
    </a>
  ) : (
    <a target="_blank" rel="noopener noreferrer" {...{ href }}>
      {children}
    </a>
  )
}
