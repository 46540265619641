import React, { PropsWithChildren, useCallback, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
const container = "csdickc";
const refContainer = "r13x97du";
type Props = {
  handleElementIsInView?: () => void;
};
export const FadeInChild = ({
  children,
  handleElementIsInView
}: PropsWithChildren<Props>) => {
  const [checkIfElementIsInView, elementIsInView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });
  const ref = useRef<HTMLDivElement>();
  useEffect(() => {
    if (elementIsInView && handleElementIsInView) handleElementIsInView();
  }, [elementIsInView, handleElementIsInView]);
  const setRefs = useCallback(node => {
    ref.current = node;
    checkIfElementIsInView(node);
  }, [checkIfElementIsInView]);
  return <div className={container} data-visible={elementIsInView ? 'visible' : 'hidden'} ref={setRefs}>
      <div className={refContainer} {...{
      ref
    }}>
        {children}
      </div>
    </div>;
};

require("./FadeInChild.linaria.module.css!=!../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./FadeInChild.tsx");