import React, { PropsWithChildren } from 'react';
import { MenuItem as ReachMenuItem } from '@reach/menu-button';
const styles = "s1ew7y0u";
type Props = {
  onSelect: () => void;
};
export const MenuItem = ({
  onSelect,
  children
}: PropsWithChildren<Props>) => {
  return <ReachMenuItem className={styles} {...{
    onSelect
  }}>
      {children}
    </ReachMenuItem>;
};

require("./MenuItem.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./MenuItem.tsx");