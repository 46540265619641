import { useState, useEffect } from 'react'
import loadImage from '../load-image'

type States = 'loading' | 'loaded' | 'failed'

export const useImage = (src: string) => {
  const [status, setStatus] = useState<States>('loading')

  useEffect(() => {
    if (!src || status === 'loaded') return

    const load = async () => {
      try {
        await loadImage(src)
        setStatus('loaded')
      } catch (error) {
        setStatus('failed')
      }
    }
    load()
  }, [src]) //eslint-disable-line

  return [status]
}
