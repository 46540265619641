import { parseAll, Theme } from '@config/theme';
import { cx } from '@linaria/core';
import React, { HTMLAttributes, Ref } from 'react';
import { filterProps, ThemeSystemProps } from 'theme-system';
const styles = "s1gr4jn3";
type Props = Omit<HTMLAttributes<HTMLElement>, 'color'> & ThemeSystemProps<Theme> & {
  as?: string;
  innerRef?: Ref<HTMLElement>;
};
export const Container = ({
  children,
  className,
  as = 'section',
  innerRef,
  ...rest
}: Props) => {
  const Comp: any = as;
  return <Comp className={cx(parseAll(rest), className, styles)} {...filterProps(rest)} ref={innerRef}>
      {children}
    </Comp>;
};
Container.displayName = 'Container';
export default Container;

require("./Container.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Container.tsx");