import { Button } from '@components/ui/Button'
import Box from '@components/ui/layout/Box'
import { Heading, Text } from '@components/ui/typography'
import React, { FC } from 'react'
import { parseAll, Theme } from '@config/theme'
import { ThemeSystemProps } from 'theme-system'
import * as styles from './styles'
import { cx } from '@linaria/core'
import SmallContainer from '@components/ui/layout/SmallContainer'

type CtaBlockProps = ThemeSystemProps<Theme> & {
  variant?: 'default' | 'margin'
  title: string
  description?: string
  button?: string
  handleClick: () => void
}

export const CtaBlock: FC<CtaBlockProps> = ({
  variant = 'default',
  title,
  description,
  button = 'Get in touch',
  handleClick,
  ...rest
}) => {
  return (
    <SmallContainer
      display="flex"
      className={cx(
        parseAll(rest),
        styles.ctaStyling,
        variant === 'margin' && styles.ctaExtraMarginBottom
      )}
    >
      <Box>
        <Heading as="h3" variant="h3" className={styles.ctaText}>
          {title}
        </Heading>
        {description && (
          <Text variant="bodyLarge" className={styles.ctaDescription}>
            {description}
          </Text>
        )}
      </Box>
      <Button
        variant="gradient"
        as="button"
        showIcon
        size="large"
        className={styles.ctaButton}
        onClick={handleClick}
      >
        <Text as="span" variant="buttonLabelRegular">
          Get in touch
        </Text>
      </Button>
    </SmallContainer>
  )
}
