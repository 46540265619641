import React, { FC, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import * as styles from './styles'
import { Text } from '@components/ui/typography'

import Box from '@components/ui/layout/Box'

type Props = {
  answer: string
  question: string
}

export const Faq: FC<Props> = ({ question, answer }) => {
  const [showAnswer, setShowAnswer] = useState(false)

  return (
    <Box className={styles.container} mb={{ _: '4' }}>
      <button
        className={styles.questionStyling}
        data-expanded={showAnswer}
        onClick={() => setShowAnswer((x) => !x)}
      >
        <span className={styles.caret} data-expanded={showAnswer}>
          ▸
        </span>
        <Text
          as="span"
          fontWeight={'semiBold'}
          mb={{ _: '2' }}
          ml={{ _: '2', sm: '4' }}
          variant="bodyRegular"
        >
          {question}
        </Text>
      </button>
      <AnimatePresence>
        {showAnswer && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <Text as="p" variant="bodyRegular" ml={{ _: '4', sm: '6' }} mb={{ _: '4' }}>
              {answer}
            </Text>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  )
}
