const loadImage = (url: string, crossOrigin?) => {
  const image = new Image()

  if (crossOrigin) image.crossOrigin = crossOrigin

  return new Promise((resolve, reject) => {
    const loaded = (event) => {
      resolve(event.target)
    }

    const errored = (error) => {
      reject(error)
    }

    image.onload = loaded
    image.onerror = errored
    image.onabort = errored

    image.src = url
  })
}

export default loadImage
