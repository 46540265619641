import { useEffect, useState } from 'react'

export const useBreakpoint = (minWidth: string): boolean => {
  const [isMinWidth, setIsMinWidth] = useState<boolean>(false)
  useEffect(() => {
    const mediaQuery = window.matchMedia(`(min-width: ${minWidth})`)

    function handleMediaQueryChange(mql) {
      setIsMinWidth(mql.matches ? true : false)
    }

    handleMediaQueryChange(mediaQuery)
    mediaQuery.addListener(handleMediaQueryChange)
    return () => {
      mediaQuery.removeListener(handleMediaQueryChange)
    }
  }, []) // eslint-disable-line

  return isMinWidth
}
