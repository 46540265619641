import { useState, useEffect } from 'react'
import { useDebouncedCallback } from 'use-debounce'

export const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(undefined)

  const [resizeHandler] = useDebouncedCallback(() => {
    setWindowWidth(window.innerWidth)
  }, 200)

  useEffect(() => {
    window.addEventListener('resize', resizeHandler)

    resizeHandler()

    return () => window.removeEventListener('resize', resizeHandler)
  }, []) // eslint-disable-line

  return windowWidth
}
